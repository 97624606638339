import type { TeactNode } from '../../lib/teact/teact';
import React, { memo } from '../../lib/teact/teact';

import type { OwnProps as FeeProps } from './Fee';

import buildClassName from '../../util/buildClassName';

import useLang from '../../hooks/useLang';

import Fee from './Fee';
import Transition from './Transition';

import styles from './FeeLine.module.scss';

/**
 * The component will be rendered empty unless all the required options from `FeeProps` are provided.
 * After that it will fade in.
 */
type OwnProps = Partial<Pick<FeeProps, 'terms' | 'token'>> & Pick<FeeProps, 'precision'> & {
  className?: string;
  /** Whether the component is rendered on a landscape layout (with a lighter background) */
  isStatic?: boolean;
  /** If undefined, the details button is not shown */
  onDetailsClick?(): void;
};

function FeeLine({
  className,
  isStatic,
  terms,
  token,
  precision,
  onDetailsClick,
}: OwnProps) {
  const lang = useLang();
  let transitionKey = 0;
  let content: TeactNode = ' ';

  if (terms && token) {
    const langKey = precision === 'exact'
      ? onDetailsClick ? '$fee_value_with_colon_and_period' : '$fee_value_with_colon'
      : onDetailsClick ? '$fee_value_with_period' : '$fee_value';

    transitionKey = onDetailsClick ? 2 : 1;
    content = (
      <>
        {lang(langKey, {
          fee: <Fee terms={terms} token={token} precision={precision} />,
        })}
        {onDetailsClick && (
          <span
            role="button"
            tabIndex={0}
            className={styles.details}
            onClick={() => onDetailsClick()}
          >
            {lang('Details')}
            <i className={buildClassName('icon-chevron-right', styles.detailsIcon)} aria-hidden />
          </span>
        )}
      </>
    );
  }

  return (
    <Transition
      name="fade"
      activeKey={transitionKey}
      className={buildClassName(styles.root, className, isStatic && styles.static)}
    >
      {content}
    </Transition>
  );
}

export default memo(FeeLine);
